<template>
    <div :class="className" :style="{height:height,width:width}" />
  </template>
  <script>
  import * as echarts from 'echarts'
  import "echarts-gl";
  import { debounce } from '@/utils'
  import { markRaw } from 'vue'
  export default {
    props: {
      className: {
        type: String,
        default: 'chart'
      },
      width: {
        type: String,
        default: '100%'
      },
      height: {
        type: String,
        default: '100%'
      },
      dataY: {
        type: Array,
        default: ()=>{
          return []
        }
      },
      dataX: {
        type: Array,
        default: ()=>{
          return []
        }
      },
      units:{
        type:String
      },
    },

    data() {
      return {
        chart:null
      }
    },
    watch:{
      'dataY':{
        handler() {
           this.refreshData()
        },
        deep:true,
      },
    },
    mounted() {
      this.refreshData()
      this.__resizeHandler = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 500)
      window.addEventListener('resize', this.__resizeHandler)
    },
    beforeUnmount() {
      if (!this.chart) {
        return
      }
      window.removeEventListener('resize', this.__resizeHandler)
      this.chart.dispose()
      this.chart = null
    },
    methods: {
      initChart() {
          this.chart = markRaw(echarts.init(this.$el))
          this.setOption()
      },
      setOption(){
        this.chart.setOption({
            grid: {
                left: "2%",
                top: "10%",
                bottom: '0%',
                right: '2%',
                containLabel: true
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
            },
            xAxis: {
                type: "category",
                data:this.dataX,
                splitLine: { show: false },
                axisLine: {
                    show: true,
                },
                axisLabel: {
                    interval: 0,//使x轴文字显示全
                    show: true,
                    color: "#fff",
                    fontSize: 12,
                    formatter: function(params) {
                      var newParamsName = "";
                      var paramsNameNumber = params.length;
                      var provideNumber = 2; //一行显示几个字
                      var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
                      if (paramsNameNumber > provideNumber) {
                        for (var p = 0; p < rowNumber; p++) {
                          var tempStr = "";
                          var start = p * provideNumber;
                          var end = start + provideNumber;
                          if (p == rowNumber - 1) {
                            tempStr = params.substring(start, paramsNameNumber);
                          } else {
                            tempStr = params.substring(start, end) + "\n";
                          }
                          newParamsName += tempStr;
                        }
                      } else {
                        newParamsName = params;
                      }
                      return newParamsName;
                    }
                },
                axisTick: {
                    show: false,
                },
            },
            yAxis:{
                type: "value",
                splitLine: { show: false },
                axisLine: {
                    show: true,
                },
                axisLabel: {
                    show: true,
                    
                    color: "#fff",
                    fontSize: 12,
                   
                },
                axisTick: {
                    show: false,
                },
            },
            series: [
                {
                    name: "报警数量",
                    type: "bar",
                    barWidth: 20,
                    data: this.dataY,
                    itemStyle: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                          offset: 0,
                          color: '#FF720B'
                      },
                      {
                          offset: 1,
                          color: '#E23D3D'
                      }
                      ]),
                    },
                    emphasis: {
                      itemStyle:{
                        opacity: 1,
                      }
                    },
                },
            ],
        })
      },
      refreshData(){
          //刷新数据
          if(this.chart){
            this.setOption();  
          }else{
            this.initChart()
          }  
      }
    }
  }
  
  </script>